import { useCallback, useEffect, useState } from 'react';

import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { Typography, createTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';

import { PrimaryButton } from '../../../components/Buttons';
import { GENERIC_ERROR, UIModes } from '../../../entities';
import { PaymentMethodType } from '../../../entities/api/paymentMethod';
import LinkError from '../../../entities/LinkError';
import useAPIClient from '../../../hooks/useClient';
import useCustomizations from '../../../hooks/useCustomizations';
import usePostPaymentFlow from '../../../hooks/usePostPaymentFlow';
import useSearchParams from '../../../hooks/useSearchParams';
import { PaymentRoutes } from '../../../routers/routes';
import { getTheme } from '../../../services';
import amplitude, { PAGE_VIEWS } from '../../../services/amplitude';
import { getApiErrorPath } from '../../../utils/error_page';
import { GenericLoadingScreen } from '../../common/Screen';
import { getErrorPath } from '../../common/utils';
import { GenericInfoScreen } from '../../Onboarding/MessageScreen';
import { BottomContainerContent } from '../BottomContainer';

export default function PaymentSubmittedScreen(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const client = useAPIClient();
  const { params, searchParamsString } = useSearchParams();
  const { customizationInfo } = useCustomizations();
  const uiMode = params.uiMode;
  const theme = createTheme(getTheme());
  const [loading, setLoading] = useState(true);
  const { postFlowEnabled, error, processing: postPaymentFlowLoading } = usePostPaymentFlow();

  const [paymentMethodType, setPaymentMethodType] = useState<PaymentMethodType>(
    (params.paymentMethodType || PaymentMethodType.UNKNOWN) as PaymentMethodType,
  );
  // this is the intermediate recipient who owns the intermediate account
  // in dbs-hk world, this would be Finverse. Finverse sends money to recipient customer
  // in dbs-sg world, this could be Zetl or the customer.
  const [recipientEntityName, setRecipientEntityName] = useState(params.recipientEntityName);

  const getPageData = useCallback(async () => {
    try {
      const shouldFetch =
        !Object.values(PaymentMethodType).includes(paymentMethodType) ||
        paymentMethodType === PaymentMethodType.UNKNOWN ||
        recipientEntityName === '' ||
        recipientEntityName === undefined;

      if (!shouldFetch) {
        // we already have the data, no need to fetch
        setLoading(false);
        return;
      }

      const paymentMethod = await client.getPaymentMethod(params.token);
      setPaymentMethodType(paymentMethod.payment_method_type);
      setRecipientEntityName(paymentMethod.recipient_entity_name);

      // finally set loading as false to indicate we can display the page now
      setLoading(false);
    } catch (err) {
      history.push(
        getErrorPath({
          institutionId: params.institutionId ?? 'testbank',
          error: new LinkError(GENERIC_ERROR),
          searchParamsString,
        }),
      );
    }
  }, [history, client, params, searchParamsString, paymentMethodType, recipientEntityName]);

  useEffect(() => {
    getPageData();
  });

  useEffect(() => {
    const eventProperties = amplitude.getLandingPageProperties();
    amplitude.trackPageView(PAGE_VIEWS.PAYMENT_SUBMITTED, {
      ...eventProperties,
      title: t('processing', { lng: 'en' }),
    });
  }, [t]);

  if (loading || postPaymentFlowLoading) {
    return <GenericLoadingScreen />;
  }

  if (error !== undefined) {
    return <Redirect to={getApiErrorPath(error, searchParamsString)} />;
  }

  const Icon = <CurrencyExchangeIcon color="primary" />;
  const Title = (
    <Typography variant="h5" fontWeight="bold">
      {t('paymentRequestSubmitted')}
    </Typography>
  );

  const PageContent = (
    <>
      {paymentMethodType === PaymentMethodType.CARD && (
        <Typography gutterBottom>{t('paymentRequestSubmittedCardText')}</Typography>
      )}
      {paymentMethodType === PaymentMethodType.MANDATE && (
        <Typography gutterBottom>
          {t('paymentRequestSubmittedMandateSuccessText')}: <b>{recipientEntityName}</b>.
        </Typography>
      )}
      <Typography gutterBottom fontWeight="bold">
        {t('doNotRetryPayment')}
      </Typography>
    </>
  );

  return (
    <GenericInfoScreen
      theme={theme}
      uiMode={uiMode as UIModes}
      title="processing"
      logoUrl={customizationInfo.logoUrl}
      iconComponent={Icon}
      titleComponent={Title}
      pageContentComponent={PageContent}
      bottomComponent={
        postFlowEnabled ? (
          <PrimaryButton
            variant="contained"
            onClick={() =>
              history.push({
                pathname: PaymentRoutes.AutopayConsent,
                search: searchParamsString,
              })
            }
          >
            {t('messageScreenContinue')}
          </PrimaryButton>
        ) : (
          <BottomContainerContent />
        )
      }
    />
  );
}
