import { useEffect } from 'react';

import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { createTheme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';

import { PrimaryButton } from '../../components/Buttons';
import { ProductFlow } from '../../constants/onboarding';
import { UIModes } from '../../entities';
import usePostPaymentFlow, { usePostPaymentFlowFlag } from '../../hooks/usePostPaymentFlow';
import useSearchParams from '../../hooks/useSearchParams';
import { PaymentRoutes } from '../../routers/routes';
import { getProductFlow, getTheme } from '../../services';
import amplitude, { PAGE_VIEWS } from '../../services/amplitude';
import { getApiErrorPath } from '../../utils/error_page';
import { GenericLoadingScreen } from '../common/Screen';
import { GenericInfoScreen } from '../Onboarding/MessageScreen';
import { BottomContainerContent } from '../payment/BottomContainer';

export default function MandateSetupProcessingScreen(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { params, searchParamsString } = useSearchParams();
  const uiMode = params.uiMode;
  const institutionId = params.institutionId;
  const theme = createTheme(getTheme());
  const productFlow = getProductFlow(params.token);
  // we will only display post-flow if this is payment link in setup mode; else do not make extra API calls
  const { postFlowEnabled, error, processing } = usePostPaymentFlow(
    productFlow === ProductFlow.PaymentLinkSetup
      ? usePostPaymentFlowFlag.NO_FLAG
      : usePostPaymentFlowFlag.FORCE_DISABLE,
  );

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.MANDATE_SETUP_PROCESSING, undefined, {
      institutionId,
    });
  }, [institutionId]);

  if (processing) {
    return <GenericLoadingScreen />;
  }

  if (error !== undefined) {
    return <Redirect to={getApiErrorPath(error, searchParamsString)} />;
  }

  const BottomComponent = (() => {
    if (postFlowEnabled) {
      return (
        <PrimaryButton
          variant="contained"
          onClick={() => history.push({ pathname: PaymentRoutes.AutopayConsent, search: searchParamsString })}
        >
          {t('messageScreenContinue')}
        </PrimaryButton>
      );
    }

    if (productFlow === ProductFlow.PaymentLink) {
      return (
        <PrimaryButton
          variant="contained"
          onClick={() => history.push({ pathname: PaymentRoutes.PaymentConfirm, search: searchParamsString })}
        >
          {t('messageScreenContinue')}
        </PrimaryButton>
      );
    }

    return <BottomContainerContent />;
  })();

  // TODO: Update Finverse Technologies Ltd with appropriate recipient entity name
  return (
    <GenericInfoScreen
      theme={theme}
      uiMode={uiMode as UIModes}
      title="processing"
      institutionId={institutionId}
      iconComponent={<CurrencyExchangeIcon color="primary" />}
      titleComponent={
        <Typography variant="h5" fontWeight="bold">
          {t('mandateAuthChecklistSubmittedHeader')}
        </Typography>
      }
      pageContentComponent={
        <Typography gutterBottom>
          {t('mandateAuthChecklistSubmittedText')}: <b>Finverse Technologies Limited</b>
        </Typography>
      }
      bottomComponent={BottomComponent}
    />
  );
}
