import { ReactElement, useEffect, useState } from 'react';

import { CircularProgress, createTheme, ThemeProvider } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams, useLocation } from 'react-router-dom';

import { MidContainer } from '../../components/Containers';
import { Buttons } from '../../components/JSONToComponent/Buttons';
import { Header } from '../../components/JSONToComponent/Header';
import { InputFields } from '../../components/JSONToComponent/InputFields';
import { TextTable } from '../../components/JSONToComponent/Table';
import { BodyOrHighlightText, FooterText, TitleText } from '../../components/JSONToComponent/Text';
import withLinking, { InjectedLinkingProps } from '../../components/withLinking';
import withSubmitAndAction, { InjectedSubmitAndActionProps, SubmitData } from '../../components/withSubmitAndAction';
import { ProductFlow, MandateAuthorizationScreens } from '../../constants/onboarding';
import { ActionRequiredData, ActionRequiredScreenState, LinkStatusType, UIModes } from '../../entities';
import useActionRequiredPages from '../../hooks/useActionRequiredPages';
import useHistoryStack from '../../hooks/useHistoryStack';
import useMandateAuthorizationHelper from '../../hooks/useMandateAuthorizationHelper';
import useSearchParams from '../../hooks/useSearchParams';
import { getProductFlow, getTheme } from '../../services';
import amplitude, { PAGE_VIEWS, cleanActionRequiredData } from '../../services/amplitude';
import Screen from '../common/Screen';
import { useStyles } from '../common/styles';

type ParamTypes = {
  institutionId: string;
};

export interface SubmitCredentialInputs {
  value: string;
}

const ActionScreenDisplay = ({
  institutionId,
  screenData,
}: {
  institutionId: string;
  screenData?: ActionRequiredData;
}): ReactElement => {
  // Themes
  const theme = createTheme(getTheme(institutionId));
  const classes = useStyles(theme);

  const getBodyOrHighlightTextStyles = () => {
    const classArr = [];
    if (screenData?.type === LinkStatusType.ACTION) {
      classArr.push(classes.infoPrompt);
    }
    return classArr.join('');
  };

  return (
    <>
      {screenData?.type === LinkStatusType.INFO && (
        <div className={classes.circularProgress}>
          <CircularProgress variant="indeterminate" size={78} color="primary" />
        </div>
      )}
      <TitleText
        name={screenData?.name}
        messages={screenData?.messages}
        align={screenData?.type === LinkStatusType.INFO ? 'center' : 'left'}
      />
      <BodyOrHighlightText
        name={screenData?.name}
        messages={screenData?.messages}
        className={getBodyOrHighlightTextStyles()}
        align={screenData?.type === LinkStatusType.INFO ? 'center' : 'left'}
      />
      <TextTable messages={screenData?.messages} />
      <FooterText
        name={screenData?.name}
        messages={screenData?.messages}
        sx={{ textAlign: 'justify', paddingTop: '0.7rem' }} // the top padding is equal to the bottom padding on infoPrompt class in src/pages/common/styles.ts
      />
    </>
  );
};

const ActionScreenForm = ({
  institutionId,
  screenData,
  disableButton,
  submitFn,
}: {
  institutionId: string;
  screenData?: ActionRequiredData;
  disableButton: boolean;
  submitFn: (data: any) => void;
}): ReactElement => {
  // Form
  const formMethods = useForm<Record<string, unknown>>({ shouldUnregister: true });
  // Themes
  const theme = createTheme(getTheme(institutionId));
  const classes = useStyles(theme);

  return (
    <FormProvider {...formMethods}>
      <form noValidate autoComplete="off" onSubmit={formMethods.handleSubmit(submitFn)}>
        <InputFields
          className={classes.actionRequiredTextInput}
          selectClassName={classes.actionRequiredSelection}
          errorClassName={classes.error}
          institutionId={institutionId}
          fields={screenData?.fields}
        />
        <Buttons
          buttonClassName={classes.actionRequiredButton}
          disableButton={disableButton}
          errorClassName={classes.error}
          institutionId={institutionId}
          buttons={screenData?.buttons}
        />
      </form>
    </FormProvider>
  );
};

const ActionRequiredScreen = ({
  onSubmit,
  checkLinkStatus,
}: InjectedSubmitAndActionProps & InjectedLinkingProps): JSX.Element => {
  const location = useLocation<ActionRequiredScreenState>();

  const screenData: ActionRequiredData | undefined = location.state?.linkStatusData;

  // Search Params
  const {
    params: { linkStatusId, uiMode, token },
  } = useSearchParams();
  const productFlow = getProductFlow(token);
  const {
    submit: handleSumbitMandateAuth,
    redirectToMandateAuthScreen: consentScreenGoBack,
    pollMandate,
  } = useMandateAuthorizationHelper();
  const { setActionData, actionData } = useActionRequiredPages();

  // URL Params
  const { institutionId } = useParams<ParamTypes>();

  // Themes
  const theme = createTheme(getTheme(institutionId));
  const classes = useStyles(theme);

  // State
  const [disableButton, setDisableButton] = useState(false);
  const { find: findHistory, historyStack } = useHistoryStack();
  // Only allow user to click go back when it is their turn to control screen
  const isAllowGoBack = screenData?.type === LinkStatusType.ACTION;

  const goBack = () => {
    amplitude.trackBackButtonClick();
    let intendedDestination = historyStack[0];
    if (productFlow == ProductFlow.DataRetrieval) {
      intendedDestination = `/onboarding/login/${institutionId}`;
    }
    if (
      productFlow === ProductFlow.Mandate ||
      productFlow === ProductFlow.PaymentLink ||
      productFlow === ProductFlow.PaymentLinkSetup
    ) {
      actionData && setActionData({}); // Reset action data when the user goes back
      switch (screenData?.name) {
        case MandateAuthorizationScreens.MANDATE_AUTHORIZATION_CONSENT:
          return consentScreenGoBack(institutionId);
        default:
          intendedDestination = '/onboarding/institutions';
          break;
      }
    }
    const destination = findHistory(intendedDestination);
    history.go(destination);
  };

  const submit = async (data: Record<string, string>) => {
    amplitude.trackButtonClick('Action Required', 'Submit Action Form');

    if (screenData?.name === MandateAuthorizationScreens.MANDATE_AUTHORIZATION) {
      return handleSumbitMandateAuth(data);
    }

    if (screenData?.name === MandateAuthorizationScreens.MANDATE_AUTHORIZATION_CONSENT) {
      return handleSumbitMandateAuth({ ...data, CONSENT_SCREEN: 'TRUE' });
    }

    setDisableButton(true);
    const submitData: SubmitData = {
      screenName: screenData?.name,
    };

    if (screenData?.name === 'CONFIRMATION') {
      await onSubmit({ value: '' }, submitData);
    } else {
      await onSubmit(data, submitData);
    }

    setDisableButton(false); // enable the button after submission
  };

  useEffect(() => {
    if (screenData?.name === MandateAuthorizationScreens.MANDATE_AUTHORIZATION) {
      return;
    }

    if (screenData?.name === MandateAuthorizationScreens.MANDATE_AUTHORIZATION_CHECK_STATUS) {
      pollMandate();
      return;
    }

    // Link status check screens
    if (linkStatusId !== null && screenData?.name === 'CHECK_STATUS') {
      checkLinkStatus();
      window.addEventListener('focus', () => checkLinkStatus());
      // Return the function to removeEventListener when this component is unmounted
      return () => window.removeEventListener('focus', () => checkLinkStatus());
    }
    // Submit for push data
    if (screenData?.name === 'PUSH_SENT') {
      submit({ value: '' });
    }
  }, [location]);

  useEffect(() => {
    if (screenData?.name === 'CHECK_STATUS') {
      amplitude.trackPageView(PAGE_VIEWS.LINKING_LOADING);
    } else {
      // need to remove action id from screenData since we save the loginIdentityId if we add action id as well,
      // a malicious actor would only need link token, and then they could submit actions on behalf of the user
      amplitude.trackPageView(PAGE_VIEWS.ACTION_REQUIRED, undefined, {
        actionRequiredData: { ...cleanActionRequiredData(screenData) },
      });
    }
  }, [screenData]);

  const getContainerStyles = () => {
    const classArr = [classes.actionRequiredPadding];
    if (screenData?.type === LinkStatusType.ACTION && screenData?.name !== 'CONFIRMATION') {
      classArr.push(classes.actionRequiredTopPadding);
    }
    return classArr.join(' ');
  };

  return (
    <ThemeProvider theme={theme}>
      <Screen showBackButton={isAllowGoBack} onBack={goBack} showCloseButton={uiMode !== UIModes.standalone}>
        <Header institutionId={institutionId} name={screenData?.name} type={screenData?.type} />
        <MidContainer className={getContainerStyles()}>
          <ActionScreenDisplay institutionId={institutionId} screenData={screenData} />
          {/* When the action require user input */}
          {screenData?.type !== LinkStatusType.INFO && (
            <ActionScreenForm
              institutionId={institutionId}
              screenData={screenData}
              submitFn={submit}
              disableButton={disableButton}
            />
          )}
        </MidContainer>
      </Screen>
    </ThemeProvider>
  );
};

export default withLinking(withSubmitAndAction(ActionRequiredScreen));
