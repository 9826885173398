import { useEffect, useMemo, useState } from 'react';

import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { MidContainer, TopContainer } from '../../../components/Containers';
import LinkHeader from '../../../components/LinkHeader';
import { ManualPaymentProvider } from '../../../constants/onboarding';
import { UIModes } from '../../../entities';
import useCustomizations from '../../../hooks/useCustomizations';
import useRedirectURI from '../../../hooks/useRedirectURI';
import useSearchParams from '../../../hooks/useSearchParams';
import { decodeToken, getManualPaymentProvider } from '../../../services';
import amplitude, { PAGE_VIEWS } from '../../../services/amplitude';
import Screen from '../../common/Screen';
import { BottomContainerContent } from '../BottomContainer';
import { CancelManualPaymentModal } from './common';

export default function FpsSubmittedScreen(): JSX.Element {
  const { t } = useTranslation();
  const { params } = useSearchParams();
  const { customizationInfo } = useCustomizations();
  const [openDialog, setOpenDialog] = useState(false);
  const uiMode = params.uiMode;

  const token = params.token;
  const format = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
  });
  const decodedToken = decodeToken(token);
  const manualPaymentProvider = getManualPaymentProvider(token);

  const { title, body } = useMemo(() => {
    if (manualPaymentProvider === ManualPaymentProvider.SG_PAYNOW) {
      return {
        title: 'PayNow Payment Submitted',
        body: 'Your PayNow details have been submitted. We will send you a notification when we confirm that your funds have been received',
      };
    }

    // continue using FPS as default
    return {
      title: 'FPS Payment Submitted',
      body: 'Your FPS details have been submitted. We will send you a notification when we confirm that your funds have been received',
    };
  }, [manualPaymentProvider]);

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.MANUAL_PAYMENT_SUBMITTED);
  }, []);

  const { setRedirectURI } = useRedirectURI();

  useEffect(() => {
    if (uiMode === UIModes.redirect && typeof params.redirect_uri === 'string') {
      setRedirectURI(params.redirect_uri);
    }
  }, [uiMode, params.redirect_uri, setRedirectURI]);

  const BottomStickyComponent = () => {
    return (
      <>
        <BottomContainerContent />
        <Button
          sx={{ paddingBottom: 2, textTransform: 'none', textDecoration: 'underline' }}
          size="large"
          onClick={() => {
            amplitude.trackButtonClick(PAGE_VIEWS.MANUAL_PAYMENT_SUBMITTED, 'Cancel payment');
            setOpenDialog(true);
          }}
          color="secondary"
        >
          {t('Cancel payment')}
        </Button>
      </>
    );
  };

  return (
    <Screen
      showCloseButton={uiMode !== UIModes.standalone}
      showBackButton={false}
      bottomStickyComponent={<BottomStickyComponent />}
    >
      <LinkHeader src={customizationInfo.logoUrl} message={t('processing')} alt="" />
      <TopContainer>
        <div className="centerLargeIcon">
          <CurrencyExchangeIcon color="primary" />
        </div>
        <Typography variant="h5" fontWeight="bold">
          {t(title)}
        </Typography>
      </TopContainer>
      <MidContainer textAlign="center" sx={{ gap: 1 }}>
        <Typography gutterBottom>{t(body)}</Typography>
        <Typography gutterBottom fontWeight="bold">
          {t('doNotRetryPayment')}
        </Typography>
      </MidContainer>
      <CancelManualPaymentModal
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        currency={decodedToken.currency}
        amount={format.format(decodedToken.amount)}
      />
    </Screen>
  );
}
