import { Switch, Route } from 'react-router-dom';

import PaymentGenericSuccessScreen from '../pages/payment/GenericSuccess';
import PaymentConfirmationScreen from '../pages/payment/payment/PaymentConfirmationScreen';
import PaymentPollingScreen from '../pages/payment/payment/PaymentPollingScreen';
import PaymentSubmittedScreen from '../pages/payment/payment/PaymentSubmittedScreen';
import AutopayConsentScreen from '../pages/payment/postPaymentFlow/AutopayConsentScreen';
import { PaymentRoutes } from './routes';

export default function PaymentRouter(): JSX.Element {
  return (
    <Switch>
      <Route path={PaymentRoutes.GenericSuccess}>
        <PaymentGenericSuccessScreen />
      </Route>
      <Route path={PaymentRoutes.AutopayConsent}>
        <AutopayConsentScreen />
      </Route>
      <Route path={PaymentRoutes.PaymentConfirm}>
        <PaymentConfirmationScreen />
      </Route>
      <Route path={PaymentRoutes.PaymentPoll}>
        <PaymentPollingScreen />
      </Route>
      <Route path={PaymentRoutes.PaymentSubmitted}>
        <PaymentSubmittedScreen />
      </Route>
    </Switch>
  );
}
