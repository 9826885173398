import { useEffect, useMemo } from 'react';

import { Icon, styled, Typography } from '@mui/material';

import { PrimaryButton } from '../../components/Buttons';
import { ConsentDisclaimer } from '../../components/ConsentDisclaimer';
import { MidContainer } from '../../components/Containers';
import Image from '../../components/Image';
import LinkHeader from '../../components/LinkHeader';
import { ContentHeader } from '../../components/Typography';
import { UIModes } from '../../entities';
import useSearchParams from '../../hooks/useSearchParams';
import amplitude, { PAGE_VIEWS } from '../../services/amplitude';
import Screen from '../common/Screen';

/**
 * This is the frontend component that defines the component rendering of the Consent Screen
 * This component is populated by values from business logic screens (at same level in folder)
 */

const ConsentCard = styled('div')(({ theme }) => ({
  width: '90%',
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: '0.4rem',
}));

export enum ConsentScreenItemCardIcon {
  FIREWALL = 1,
  USER_LOCK = 2,
  CUSTOM = 3,
}

export interface ConsentScreenItemCardProps {
  title: string;
  description: string;
  icon: ConsentScreenItemCardIcon;
  /**
   * If icon type is CUSTOM, then pass in this to specify the custom icon
   */
  customIconName?: string;
}

const ItemCard = (props: ConsentScreenItemCardProps) => {
  const getIcon = useMemo(() => {
    switch (props.icon) {
      case ConsentScreenItemCardIcon.FIREWALL:
        return (
          <Image
            sx={{ color: 'primary', height: '24px', width: '24px', colorScheme: 'primary', colorAdjust: 'primary' }}
            src="/img/icon/firewallPanic.svg"
          />
        );
      case ConsentScreenItemCardIcon.USER_LOCK:
        return (
          <Image
            sx={{ color: 'primary', height: '24px', width: '24px', colorScheme: 'primary', colorAdjust: 'primary' }}
            src="/img/icon/userLock.svg"
          />
        );
      default:
        return <Icon color="primary">{props.customIconName}</Icon>;
    }
  }, [props.icon, props.customIconName]);

  return (
    <ConsentCard>
      {getIcon}
      <div>
        <Typography
          variant="h6"
          component="h6"
          sx={{
            fontWeight: 800,
            marginBottom: '0.2em',
          }}
        >
          {props.title}
        </Typography>
        <Typography>{props.description}</Typography>
      </div>
    </ConsentCard>
  );
};

export interface ConsentScreenProps {
  /**
   * The text that appears at the top of the screen
   * E.g. Link Your Bank
   */
  header: string;
  /**
   * The URL of the top-most logo that includes customizations if any
   */
  logoUrl: string;
  /**
   * The text that appears at the top of the mid section
   * E.g. - We use Finverse to setup payments from your bank
   */
  subTitle: string;
  /**
   * The list of items to display
   * E.g. Secure: Finverse uses multiple security layers to protect your data during transfer...
   */
  items: ConsentScreenItemCardProps[];
  /**
   * The text to show on the main action button
   */
  buttonText: string;
  /**
   * This function will be called when the user clicks the main action button
   * Before this function is called, a analytic event will be fired to amplitude
   */
  buttonClickFn: () => void;
}

const ConsentScreenJsx = (props: ConsentScreenProps): JSX.Element => {
  const { params } = useSearchParams();
  const uiMode = params.uiMode;

  // on page load, send amplitude event
  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.FINVERSE_CONSENT);
  }, []);

  const BottomContent = () => (
    <>
      <PrimaryButton
        variant="contained"
        color="primary"
        onClick={() => {
          amplitude.trackButtonClick('Consent Screen', 'Continue button');
          props.buttonClickFn();
        }}
        sx={{ paddingBottom: 1 }}
      >
        {props.buttonText}
      </PrimaryButton>
      <ConsentDisclaimer />
    </>
  );

  return (
    <Screen
      showBackButton={false}
      showCloseButton={uiMode !== UIModes.standalone}
      bottomStickyComponent={BottomContent()}
    >
      <LinkHeader src={props.logoUrl} message={props.header}></LinkHeader>
      <MidContainer
        sx={{
          alignItems: 'center',
          gap: 1.5,
          padding: '10px 5px 16px 5px', // top right bottom left
        }}
      >
        <ContentHeader
          variant="h6"
          component="h6"
          textAlign="center"
          lineHeight={1.5}
          maxWidth="90%"
          marginBottom="0.4rem"
        >
          {props.subTitle}
        </ContentHeader>
        <>
          {props.items.map((itemProps) => {
            return <ItemCard {...itemProps} key={itemProps.title} />;
          })}
        </>
      </MidContainer>
    </Screen>
  );
};

export default ConsentScreenJsx;
