import { useEffect } from 'react';

import CheckIcon from '@mui/icons-material/CheckCircle';
import { Typography, createTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';

import { PrimaryButton } from '../../../components/Buttons';
import { UIModes } from '../../../entities';
import useCustomizations from '../../../hooks/useCustomizations';
import usePostPaymentFlow from '../../../hooks/usePostPaymentFlow';
import useSearchParams from '../../../hooks/useSearchParams';
import { PaymentRoutes } from '../../../routers/routes';
import { getTheme } from '../../../services';
import amplitude, { PAGE_VIEWS } from '../../../services/amplitude';
import { getApiErrorPath } from '../../../utils/error_page';
import { GenericLoadingScreen } from '../../common/Screen';
import { GenericInfoScreen } from '../../Onboarding/MessageScreen';
import { BottomContainerContent } from '../BottomContainer';

export default function SuccessCreditCardScreen(): JSX.Element {
  const { t } = useTranslation();
  const { params, searchParamsString } = useSearchParams();
  const { customizationInfo } = useCustomizations();
  const uiMode = params.uiMode;
  const theme = createTheme(getTheme());
  const { postFlowEnabled, processing, error } = usePostPaymentFlow();
  const history = useHistory();

  useEffect(() => {
    const eventProperties = amplitude.getLandingPageProperties();
    amplitude.trackPageView(PAGE_VIEWS.CARD_PAYMENT_SUCCESS, {
      ...eventProperties,
    });
  }, []);

  if (processing) {
    return <GenericLoadingScreen />;
  }

  if (error !== undefined) {
    return <Redirect to={getApiErrorPath(error, searchParamsString)} />;
  }

  const Icon = <CheckIcon color="primary" />;
  const Title = (
    <Typography variant="h5" fontWeight="bold">
      {t('Payment completed')}
    </Typography>
  );
  const PageContent = (
    <Typography gutterBottom>
      {t('Your payment has been successfully completed. You will receive an email with your payment receipt.')}
    </Typography>
  );

  return (
    <GenericInfoScreen
      theme={theme}
      uiMode={uiMode as UIModes}
      title="success"
      logoUrl={customizationInfo.logoUrl}
      iconComponent={Icon}
      titleComponent={Title}
      pageContentComponent={PageContent}
      bottomComponent={
        postFlowEnabled ? (
          <PrimaryButton
            variant="contained"
            onClick={() =>
              history.push({
                pathname: PaymentRoutes.AutopayConsent,
                search: searchParamsString,
              })
            }
          >
            {t('messageScreenContinue')}
          </PrimaryButton>
        ) : (
          <BottomContainerContent />
        )
      }
    />
  );
}
