import SyncIcon from '@mui/icons-material/Sync';
import { Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BulletTwo } from '../../../components/BulletIcons';
import { ManualPaymentProvider } from '../../../constants/onboarding';

// Created a top level component so TextField does not unselect on each keypress
// For details see this: https://levelup.gitconnected.com/react-text-input-losing-focus-after-each-keypress-dd6eabfe2f7c
interface Props {
  showErrFeedback: boolean;
  accountholderName: string;
  manualPaymentProvider: ManualPaymentProvider;
  setAccountholderName: (name: string) => void;
}

export default function InstructionTwo({
  showErrFeedback,
  accountholderName,
  setAccountholderName,
  manualPaymentProvider,
}: Props) {
  const { t } = useTranslation();
  if (manualPaymentProvider === ManualPaymentProvider.HK_FPS) {
    return (
      <>
        <Grid container alignItems="center" display="flex" sx={{ mt: 2 }} spacing={1}>
          <Grid item>
            <BulletTwo />
          </Grid>
          <Grid item>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              {t('paymentConfirm')}:
            </Typography>
          </Grid>
        </Grid>
        <TextField
          error={showErrFeedback}
          value={accountholderName}
          onChange={(e) => setAccountholderName(e.target.value)}
          sx={{ mt: 1 }}
          variant="outlined"
          label={t('Accountholder last name or company name')}
          helperText={t('This helps us identify your payment')}
        />
      </>
    );
  }

  if (manualPaymentProvider === ManualPaymentProvider.SG_PAYNOW) {
    return (
      <>
        <Grid container item alignItems="center" display="flex" spacing={1} mt={2}>
          <Grid item>
            <BulletTwo />
          </Grid>
          <Grid item>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              {t('Wait for confirmation')}:
            </Typography>
          </Grid>
        </Grid>
        <Grid container item pl={1} alignItems="center" display="flex" spacing={0.5}>
          <Grid item>
            <Typography gutterBottom>
              <b>{t('Status')}:</b> {t('Waiting for payment')}
            </Typography>
          </Grid>
          <Grid item>
            <SyncIcon fontSize="small" color="primary" />
          </Grid>
        </Grid>
        <Grid container item pl={1}>
          <Typography variant="body1">
            {t('Page will update when payment is received. You must use QR code to enable auto-confirmation.')}
          </Typography>
        </Grid>
      </>
    );
  }

  return null;
}
