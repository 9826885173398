import { useContext, useEffect } from 'react';

import { CheckCircle, Error, LockRounded, NotInterested, AccountCircleRounded, LinkOff } from '@mui/icons-material';
import { Box, Theme, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { PrimaryButton, YouCanCloseTheWindowNow } from '../../components/Buttons';
import { MidContainer, TopContainer } from '../../components/Containers';
import LinkHeader from '../../components/LinkHeader';
import { ProductFlow } from '../../constants/onboarding';
import { HistoryStackContext } from '../../contexts/HistoryStackContext';
import { LoginIdentityContext } from '../../contexts/LoginIdentityContext';
import { MandateInfoContext } from '../../contexts/MandateInfoContext';
import { UIModes } from '../../entities/api';
import { defaultCustomization } from '../../hooks/useCustomizations';
import usePostPaymentFlow, { usePostPaymentFlowFlag } from '../../hooks/usePostPaymentFlow';
import useRedirectURI from '../../hooks/useRedirectURI';
import useSearchParams from '../../hooks/useSearchParams';
import { PaymentRoutes } from '../../routers/routes';
import { getProductFlow, getTheme } from '../../services';
import amplitude, { PAGE_VIEWS } from '../../services/amplitude';
import { getApiErrorPath } from '../../utils/error_page';
import { DisplaySupportMessage } from '../common/components';
import Screen, { GenericLoadingScreen } from '../common/Screen';
import { useStyles } from '../common/styles';
import { MessageState, SCREEN_TYPE } from '../common/utils';

const getIcon = (type: string) => {
  if (type === 'unlinked') return <LinkOff color="primary" />;
  if (type === 'credentialRequired') return <AccountCircleRounded color="primary" />;
  if (type === 'notResponding') return <NotInterested color="primary" />;
  if (type === 'locked') return <LockRounded color="primary" />;
  if (type === SCREEN_TYPE.ERROR || type === SCREEN_TYPE.FVERROR) return <Error color="primary" />;
  return <CheckCircle color="primary" />;
};

const MessageBox = ({ header, body, type }: { header: string; body: string; type: string }) => {
  const { t } = useTranslation();
  return (
    <Box m={1}>
      <div className="centerLargeIcon">{getIcon(type)}</div>
      <Typography variant="h5" component="h5" align="center" gutterBottom>
        {t(header, header)}
      </Typography>
      <Typography
        variant="h6"
        component="h6"
        align="center"
        sx={{
          lineHeight: 1.2,
        }}
      >
        {t(body, body)}
      </Typography>
    </Box>
  );
};

export const ReferenceIdBox = ({
  institutionId,
  refernceId,
  message,
}: {
  institutionId: string;
  refernceId: string;
  message: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const theme = createTheme(getTheme(institutionId));
  const classes = useStyles(theme);

  return (
    <Box m={3}>
      <Typography color="secondary" align="center" className={classes.referenceIdText}>
        {t(message)}
      </Typography>
      <Typography color="secondary" align="center" className={classes.referenceIdText}>
        {refernceId}
      </Typography>
    </Box>
  );
};

const UserActionPanel = ({
  uiMode,
  screenType,
  btnText,
  btnFn,
}: {
  uiMode: string;
  screenType: string;
  btnText?: string;
  btnFn?: () => void;
}) => {
  const { t } = useTranslation();

  // Ask user to close window if it is a fatal error or it is a success (exclude retry-able error and non-final success)
  if (
    uiMode === UIModes.standalone &&
    screenType !== SCREEN_TYPE.ERROR &&
    screenType !== SCREEN_TYPE.NON_FINAL_SUCCESS
  ) {
    return <YouCanCloseTheWindowNow />;
  }

  const getButtonText = () => {
    if (btnText) {
      return t(btnText, btnText);
    }
    // Default display
    switch (screenType) {
      case SCREEN_TYPE.ERROR: {
        return t('messageScreenError');
      }
      case SCREEN_TYPE.FVERROR: {
        return t('messageScreenExit');
      }
      default:
        return t('messageScreenContinue');
    }
  };

  return (
    <PrimaryButton variant="contained" color="primary" onClick={btnFn}>
      {getButtonText()}
    </PrimaryButton>
  );
};

export default function MessageScreen(): JSX.Element {
  const history = useHistory();
  const historyStack = useContext(HistoryStackContext);
  const { state } = useLocation<MessageState>();
  const { params: searchParams, searchParamsString } = useSearchParams();
  const { loginIdentityId } = useContext(LoginIdentityContext);
  const { mandateId } = useContext(MandateInfoContext);
  const { redirectToURI } = useRedirectURI();
  const productFlow = getProductFlow(searchParams.token);

  const { type: gotType = SCREEN_TYPE.ERROR, institutionId = 'finverse', uiMode, after = '1000' } = searchParams;

  // only run the hook for setup payment link on success screen; for any other scenario we don't want to make an extra API call needlessly
  const flag =
    productFlow === ProductFlow.PaymentLinkSetup && gotType === SCREEN_TYPE.SUCCESS
      ? usePostPaymentFlowFlag.NO_FLAG
      : usePostPaymentFlowFlag.FORCE_DISABLE;
  const { processing, postFlowEnabled, error } = usePostPaymentFlow(flag);

  useEffect(() => {
    if (uiMode === UIModes.autoRedirect) {
      const redirectAfter = isNaN(parseInt(after)) ? 1000 : parseInt(after);
      setTimeout(redirectToURI, redirectAfter);
    }
  }, [after, redirectToURI, uiMode]);

  // Return a default screen if parameters are missing
  const { title, header, body } = state || {
    title: 'Error',
    header: 'errorGenericMessage',
    body: 'errorGenericDetails',
  };

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.MESSAGE_PAGE, undefined, {
      title,
      header,
      body,
      state,
    });
  }, [body, header, state, title]);

  if (processing) {
    return <GenericLoadingScreen />;
  }

  if (error) {
    return <Redirect to={getApiErrorPath(error, searchParamsString)} />;
  }

  const type = (() => {
    // for refresh, we cannot handle "try again", so we just want to show "exit"
    if (gotType === SCREEN_TYPE.ERROR && productFlow === ProductFlow.RefreshDataRetrieval) {
      return SCREEN_TYPE.FVERROR;
    }

    // for payment link flow we want to redirect to payment confirmation screen
    if (gotType === SCREEN_TYPE.SUCCESS && productFlow === ProductFlow.PaymentLink) {
      return SCREEN_TYPE.NON_FINAL_SUCCESS;
    }

    // for setup link flow, if post flow is enabled we want to redirect to autopay consent screen
    if (gotType === SCREEN_TYPE.SUCCESS && productFlow === ProductFlow.PaymentLinkSetup && postFlowEnabled) {
      return SCREEN_TYPE.NON_FINAL_SUCCESS;
    }

    return gotType;
  })();

  const theme = createTheme(getTheme(institutionId));

  const goBack = () => {
    amplitude.trackBackButtonClick({
      destination: state?.destination ?? '',
    });
    // It either return to pre-configured destination or the beginning of the link
    const destination = state?.destination ?? historyStack.historyStack[0];
    const backsteps = historyStack.find(destination);
    // Fallback to default if destination is not in history
    return history.go(backsteps ? backsteps : -1);
  };

  const displayReferenceId = () => {
    if (type === SCREEN_TYPE.ERROR) {
      if (typeof loginIdentityId === 'string' && loginIdentityId.length > 0) {
        return (
          <ReferenceIdBox institutionId={institutionId} refernceId={loginIdentityId} message="loginIdentityMessage" />
        );
      }
    }

    if (type === SCREEN_TYPE.FVERROR) {
      if (typeof mandateId === 'string' && mandateId.length > 0) {
        return <ReferenceIdBox institutionId={institutionId} refernceId={mandateId} message="mandateIdMessage" />;
      }
    }
  };

  const onClickNextStep = () => {
    amplitude.trackButtonClick('Message Screen', 'Main Action Button');

    // if the product flow is payment_link and this is non-final success screen, then go to payment confirm
    if (productFlow === ProductFlow.PaymentLink && type === SCREEN_TYPE.NON_FINAL_SUCCESS) {
      return history.push({
        pathname: PaymentRoutes.PaymentConfirm,
        search: searchParamsString,
      });
    }

    if (productFlow === ProductFlow.PaymentLinkSetup && type === SCREEN_TYPE.NON_FINAL_SUCCESS) {
      return history.push({
        pathname: PaymentRoutes.AutopayConsent,
        search: searchParamsString,
      });
    }

    //Behavior for redirect mode
    if (uiMode === UIModes.redirect || uiMode === UIModes.autoRedirect) {
      redirectToURI();
    }
    // Default behavior when it is not redirect mode
    switch (type) {
      // recoverable error => go back to beginning of the flow (usually login screen or consent page)
      case SCREEN_TYPE.ERROR: {
        return goBack();
      }
      // Non-recoverable error => end this flow
      case SCREEN_TYPE.FVERROR: {
        return window.parent.postMessage('close', '*');
      }
      // Non-error type => treat as success, close and report success
      default:
        return window.parent.postMessage('success', '*');
    }
  };

  return (
    <GetMessageScreenJSX
      theme={theme}
      uiMode={uiMode}
      goBack={goBack}
      state={state}
      title={title}
      institutionId={institutionId}
      header={header}
      body={body}
      type={type}
      displayReferenceId={displayReferenceId}
      onClickNextStep={onClickNextStep}
    />
  );
}

type messageScreenProps = {
  theme: Theme;
  uiMode: string;
  goBack: () => void;
  state: MessageState;
  title: string;
  institutionId: string;
  header: string;
  body: string;
  type: string;
  logoUrl?: string;
  productFlow?: string;
  displayReferenceId: () => JSX.Element | undefined;
  onClickNextStep: () => void;
};

export const GetMessageScreenJSX = (props: messageScreenProps): JSX.Element => {
  const { t } = useTranslation();
  const getHeader = () => {
    if (
      props.productFlow === ProductFlow.PaymentLink &&
      props.logoUrl !== undefined &&
      props.logoUrl !== defaultCustomization.logoUrl
    ) {
      return (
        <LinkHeader src={props.logoUrl} message={t(props.title, props.title)} institutionId={props.institutionId} />
      );
    }

    return <LinkHeader message={t(props.title, props.title)} institutionId={props.institutionId} />;
  };

  const isPayment =
    props.productFlow !== ProductFlow.DataRetrieval &&
    props.productFlow !== ProductFlow.RefreshDataRetrieval &&
    props.productFlow !== ProductFlow.Unknown &&
    props.productFlow !== undefined;

  return (
    <ThemeProvider theme={props.theme}>
      <Screen
        showCloseButton={props.uiMode !== UIModes.standalone}
        onBack={props.goBack}
        showBackButton={typeof props.state?.destination !== 'undefined'} // show back button if destination is provided
        bottomStickyComponent={
          <UserActionPanel
            screenType={props.type}
            uiMode={props.uiMode}
            btnFn={props.onClickNextStep}
            btnText={props.state?.buttonText}
          />
        }
      >
        {getHeader()}
        <MidContainer
          sx={{
            alignItems: 'center',
            gap: 1,
          }}
        >
          <MessageBox header={props.header} body={props.body} type={props.type} />
          {isPayment && DisplaySupportMessage()}
          {props.displayReferenceId()}
        </MidContainer>
      </Screen>
    </ThemeProvider>
  );
};

type genericInfoScreenProps = {
  theme: Theme;
  uiMode: UIModes;
  showBackButton?: boolean;

  // Header props
  title: string;
  logoUrl?: string;
  institutionId?: string;

  iconComponent: JSX.Element;
  titleComponent: JSX.Element;
  pageContentComponent: JSX.Element;
  bottomComponent: JSX.Element;
};
/**
 * The purpose of this component is to provide a common interface for the "newer" message screens.
 *
 * GetMessageScreenJSX uses some older components whereas newer message screens use common components
 * to abstract out logic like page action button based on ui-mode, etc.
 *
 * This component also allows the caller to flexibly pass in the content for the page
 */
export const GenericInfoScreen = (props: genericInfoScreenProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={props.theme}>
      <Screen
        showCloseButton={props.uiMode !== UIModes.standalone}
        bottomStickyComponent={props.bottomComponent}
        showBackButton={props.showBackButton}
      >
        <LinkHeader src={props.logoUrl} message={t(props.title)} alt="" institutionId={props.institutionId} />
        <TopContainer>
          <div className="centerLargeIcon">{props.iconComponent}</div>
          {props.titleComponent}
        </TopContainer>
        <MidContainer textAlign="center" sx={{ gap: 1 }}>
          {props.pageContentComponent}
        </MidContainer>
      </Screen>
    </ThemeProvider>
  );
};
