import { useCallback, useEffect, useState } from 'react';

import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { LoadingButton } from '../../../components/Buttons';
import { MidContainer } from '../../../components/Containers';
import LinkHeader from '../../../components/LinkHeader';
import { MandateSenderType } from '../../../entities/api/mandate';
import useAPIClient from '../../../hooks/useClient';
import useCustomizations from '../../../hooks/useCustomizations';
import useSearchParams from '../../../hooks/useSearchParams';
import { OnboardingRoutes } from '../../../routers/routes';
import { decodeToken } from '../../../services';
import amplitude, { PAGE_VIEWS } from '../../../services/amplitude';
import { getApiErrorPath } from '../../../utils/error_page';
import Screen from '../../common/Screen';

// TODO: This page should ideally be in Onboarding folder. Move this page into onboarding during work on cleaning up FVLink flow
export default function SelectSenderTypeScreen(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { customizationInfo } = useCustomizations();
  const { params, searchParamsString } = useSearchParams();
  const client = useAPIClient();

  const [loading, setLoading] = useState(false);
  const [senderType, setSenderType] = useState<MandateSenderType>(MandateSenderType.INDIVIDUAL);

  const token = params.token;
  const { paymentAttemptId } = decodeToken(token);

  const submitSenderType = useCallback(
    async (sender_type: MandateSenderType) => {
      //step 1: Mark the button as loading
      setLoading(true);

      // step 2: Submit the sender type
      try {
        const response = await client.submitSenderTypeToCreateMandate(token, {
          sender_type,
          payment_attempt_id: paymentAttemptId,
        });
        // step 3: If success, then get token and go to institution page
        const newSearchParams = new URL(response.mandate_link_token.link_url).searchParams;
        history.push({ pathname: OnboardingRoutes.Institutions, search: newSearchParams.toString() });
      } catch (err) {
        history.push(getApiErrorPath(err as any, searchParamsString));
      }
    },
    [history, client, paymentAttemptId, token, searchParamsString],
  );

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.MANDATE_USER_TYPE_SELECTION);
  }, []);

  return (
    <Screen
      showCloseButton={false}
      bottomStickyComponent={
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => {
            amplitude.trackButtonClick(PAGE_VIEWS.MANDATE_USER_TYPE_SELECTION, 'Select Mandate Sender Type', {
              mandateSenderType: senderType,
            });
            submitSenderType(senderType);
          }}
          size="large"
        >
          {t('messageScreenContinue')}
        </LoadingButton>
      }
    >
      <LinkHeader src={customizationInfo.logoUrl} message={t('selectAccountType')} alt="Header logo" />
      <MidContainer sx={{ px: 4, py: 3 }}>
        <Typography fontWeight="bold" gutterBottom variant="h6">
          {t('accountType')}:
        </Typography>
        <FormControl>
          <RadioGroup value={senderType} onChange={(e) => setSenderType(e.target.value as MandateSenderType)}>
            <FormControlLabel
              value={MandateSenderType.INDIVIDUAL}
              control={<Radio />}
              label={t<string>('individual')}
            />
            <FormControlLabel value={MandateSenderType.BUSINESS} control={<Radio />} label={t<string>('business')} />
          </RadioGroup>
        </FormControl>
      </MidContainer>
    </Screen>
  );
}
