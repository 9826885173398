import { useEffect, useMemo } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import HelpOutline from '@mui/icons-material/HelpOutline';
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Tooltip,
  ClickAwayListener,
  Backdrop,
  CircularProgress,
  ThemeProvider,
  useTheme,
  createTheme,
  Alert,
  Box,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, Link as ReactRouterLink } from 'react-router-dom';
import useSWR from 'swr/immutable';

import { BottomContainer, MidContainer } from '../../components/Containers';
import Image from '../../components/Image';
import LinkHeader from '../../components/LinkHeader';
import LinkPassword from '../../components/LinkPassword';
import { getEnv } from '../../config';
import { LoadingScreenData } from '../../constants/linkStatusData/linkStatusData';
import { FINVERSE_OAUTH_ERROR, LoginField, UIModes, GENERIC_ERROR, LoginMethod } from '../../entities';
import { LinkToken } from '../../entities/api/token';
import LinkError from '../../entities/LinkError';
import useAPIClient from '../../hooks/useClient';
import useLoginHelper from '../../hooks/useLoginHelper';
import useSearchParams from '../../hooks/useSearchParams';
import useToggle from '../../hooks/useToggle';
import { getTheme } from '../../services';
import amplitude, { PAGE_VIEWS } from '../../services/amplitude';
import Screen from '../common/Screen';
import { useStyles } from '../common/styles';
import { getDataRefreshCheckboxConfig, getErrorPath, parseToken } from '../common/utils';

// Only secret required should be boolean,
// but it is not currently possible to exclude a string literal from a string type
type Inputs = Record<string, string | boolean>;

const filterLoginMethods = (loginMethods?: LoginMethod[]) => {
  const env = getEnv(window.location.hostname);
  return loginMethods?.filter((loginMethod) => {
    if (env === 'prod') {
      return loginMethod.status === 'SUPPORTED';
    }

    if (env === 'dev2') {
      return loginMethod.status === 'SUPPORTED' || loginMethod.status === 'BETA' || loginMethod.status === 'ALPHA';
    }

    if (env === 'local') {
      // Want to make it easy for local development
      // Will whitelist all login methods for local
      return true;
    }

    return false;
  });
};

const LoginForm = ({ loginFields, submitFn }: { loginFields?: LoginField[]; submitFn: (values: Inputs) => void }) => {
  const classes = useStyles(useTheme());
  const { t } = useTranslation();
  const history = useHistory();
  const { params } = useSearchParams();
  const [tooltipOpen, toggleTooltip] = useToggle(false);
  const { institutionId } = useParams<{ institutionId: string }>();

  const accessToken: string = params.token ?? '';
  const decoded = parseToken<LinkToken>(accessToken);
  const automaticRefreshCheckboxDetails = getDataRefreshCheckboxConfig(decoded);
  const { checked, disabled } = automaticRefreshCheckboxDetails;

  const initialFormData = useMemo(
    () => loginFields?.reduce((acc, { key }) => ({ ...acc, [key]: '' }), {}) ?? {},
    [loginFields],
  );
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      ...initialFormData,
      storeCredentials: checked, // inherit checked state from link token
    },
  });

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  useEffect(() => {
    if (loginFields === undefined || loginFields.length === 0) {
      const error = new LinkError(GENERIC_ERROR);
      history.push(
        getErrorPath({
          institutionId,
          error,
          userState: params.state,
          searchParamsString: location.search,
        }),
      );
    }
  }, [history, institutionId, loginFields, params.state]);

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(submitFn)}>
      {loginFields?.map(({ key, name, type }, index) => {
        switch (type) {
          case 'password':
            return (
              <Box sx={{ marginBottom: 1 }} key={index}>
                <LinkPassword
                  id={key}
                  name={key}
                  label={t(name)}
                  style={classes.textInput}
                  register={register}
                  autoFocus={index === 0}
                />
                <ErrorMessage
                  errors={errors}
                  name={key}
                  as="p"
                  className={[classes.error, classes.marginBottomNone].join(' ')}
                />
              </Box>
            );
          case 'text':
            return (
              <Box sx={{ marginBottom: 1 }} key={index}>
                <TextField
                  {...register(key, { required: `${name} is required` })}
                  id={key}
                  name={key}
                  label={t(name)}
                  variant="outlined"
                  className={classes.textInput}
                  type="text"
                  autoFocus={index === 0}
                  inputProps={{ autoCapitalize: 'none' }}
                />
                <ErrorMessage
                  errors={errors}
                  name={key}
                  as="p"
                  className={[classes.error, classes.marginBottomNone].join(' ')}
                />
              </Box>
            );
          default:
            return null;
        }
      })}

      <Box>
        <Controller
          name="storeCredentials"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              {...field}
              control={<Checkbox id="storeCredentials" name="storeCredentials" color="primary" />}
              checked={Boolean(field.value)}
              disabled={disabled} // inherit disabled state from link token
              className={classes.controlLabel}
              label={t('stepsAutoRefreshHeader') as string}
            />
          )}
        />
        <ClickAwayListener onClickAway={() => toggleTooltip(false)}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={String(t('stepsAutoRefreshText'))}
            arrow
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => toggleTooltip()}
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <HelpOutline onClick={() => toggleTooltip()} className={classes.icon} />
          </Tooltip>
        </ClickAwayListener>
      </Box>

      <Button fullWidth={true} variant="contained" color="primary" type="submit" className={classes.button}>
        {t('stepsLoginButtonText')}
      </Button>
    </form>
  );
};

function AlternateLoginMethods({ loginMethods }: { loginMethods?: LoginMethod[] }): JSX.Element {
  const { institutionId } = useParams<{ institutionId: string }>();
  const { params } = useSearchParams();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {loginMethods?.map((loginMethod) => {
        const search = new URLSearchParams({ ...params, login_method_id: loginMethod.id }).toString();
        return (
          <Button
            key={loginMethod.id}
            component={ReactRouterLink}
            to={`/onboarding/login/${institutionId}?${search}`}
            size="small"
            sx={{ textTransform: 'none', '&:hover': { textDecoration: 'underline' } }}
          >
            <Typography>Login with {loginMethod.name}</Typography>
          </Button>
        );
      })}
    </Box>
  );
}

export default function LoginScreen(): JSX.Element {
  const history = useHistory();
  const { institutionId } = useParams<{ institutionId: string }>();
  const { t } = useTranslation();

  // Override default theme with institution specific theme
  const theme = createTheme(getTheme(institutionId));

  const client = useAPIClient();
  const { submit } = useLoginHelper();
  const { params, searchParamsString } = useSearchParams();
  const language = params.language || 'en';
  const uiMode = params.uiMode;

  const [loading, toggleLoading] = useToggle(false);

  const { data, error } = useSWR(
    [`/${institutionId}`, institutionId, params.token],
    (_: string, institutionId: string, token: string) => client.getInstitution(token, institutionId),
  );

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.LOGIN);
  }, []);

  useEffect(() => {
    if (error) {
      const dest = getErrorPath({
        institutionId: 'finverse',
        error,
        userState: '',
        searchParamsString: location.search,
      });
      history.push(dest);
    }
  }, [error, history, language]);

  const userState = params.state;

  const loginMethod = useMemo(() => {
    const filteredLoginMethod = filterLoginMethods(data?.login_methods);
    let loginMethodData = filteredLoginMethod?.find((loginMethod) => params.login_method_id === loginMethod.id);

    if (!loginMethodData) {
      loginMethodData = filteredLoginMethod?.find((loginMethod) => loginMethod.is_default_method);
    }

    if (!loginMethodData) {
      return undefined;
    }

    return loginMethodData;
  }, [data?.login_methods, params.login_method_id]);

  const login = async (data: Inputs) => {
    if (loading) return;
    toggleLoading(true);

    const { token } = params;
    try {
      if (token && token.length) {
        const { storeCredentials, ...inputs } = data;
        const stringifiedInputs = Object.entries(inputs).reduce(
          (acc, [key, value]) => ({ ...acc, [key]: String(value) }),
          {} as Record<string, string>,
        );

        const result = await submit(stringifiedInputs, Boolean(storeCredentials));
        if (!result) {
          throw new LinkError(FINVERSE_OAUTH_ERROR);
        }
        // Change state before unmount
        toggleLoading(false);
        history.push({
          pathname: `/onboarding/action/${institutionId}`,
          search: `${searchParamsString}&linkStatusId=${result.linkStatusId}`,
          state: {
            loginScreenData: { ...data },
            linkStatusData: LoadingScreenData,
          },
        });
      } else {
        // Clean up loading
        toggleLoading(false);
      }
    } catch (e) {
      toggleLoading(false);
      const error = e as Error;
      history.push(
        getErrorPath({
          institutionId,
          error,
          userState,
          searchParamsString: location.search,
        }),
      );
    }
  };

  const onSubmit = async (data: Inputs) => {
    amplitude.trackButtonClick('Login Screen', 'Submit Credentials Button');
    await login(data);
  };

  if (!data && !error) {
    return <div></div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop id="backdrop" open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Screen showBackButton showCloseButton={uiMode !== UIModes.standalone}>
        <LinkHeader message={t('stepsCredentialsHeader')} institutionId={institutionId} />
        <MidContainer
          sx={{
            gap: 2,
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 4,
          }}
        >
          {data?.login_details?.info_message && (
            <Alert severity="info">
              {
                // TODO: Temporary solution is to remove the ':', in the future we will require our fields have proper name
                t(data?.login_details?.info_message?.replace(':', '') ?? '', data?.login_details?.info_message)
              }
            </Alert>
          )}
          <LoginForm loginFields={loginMethod?.login_fields} submitFn={onSubmit} />
          <AlternateLoginMethods
            loginMethods={filterLoginMethods(data?.login_methods.filter((lm) => lm.id !== loginMethod?.id))}
          />
        </MidContainer>
        <BottomContainer>
          {t('stepsFooterText')}
          <Link
            target="_blanks"
            href="https://www.finverse.com/"
            color="primary"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              placeItem: 'center',
            }}
          >
            <Image
              src="/img/logo.svg"
              alt="Finverse"
              sx={{
                margin: 'auto',
                padding: 1,
                width: '8rem',
              }}
            />
            {t('stepsFooterLink')}
          </Link>
        </BottomContainer>
      </Screen>
    </ThemeProvider>
  );
}
