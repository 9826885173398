import { useState, useEffect, useMemo } from 'react';

import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Box, Backdrop, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import { MidContainer } from '../../components/Containers';
import LinkHeader, { institutionToLogoMap } from '../../components/LinkHeader';
import SearchBar from '../../components/SearchBar';
import { ProductFlow } from '../../constants/onboarding';
import { Institution, UIModes } from '../../entities';
import { LinkToken, MandateAuthToken } from '../../entities/api/token';
import useCustomizations, { defaultCustomization } from '../../hooks/useCustomizations';
import useDebounce from '../../hooks/useDebounce';
import useMandateAuthorizationHelper from '../../hooks/useMandateAuthorizationHelper';
import useSearchParams from '../../hooks/useSearchParams';
import { getProductFlow } from '../../services';
import amplitude, { PAGE_VIEWS } from '../../services/amplitude';
import { toCountryFullname } from '../../utils/country';
import Screen from '../common/Screen';
import {
  getErrorPath,
  getInstitutionStatuses,
  getLinkModes,
  getPaymentsSupported,
  getRealEnabled,
  parseToken,
} from '../common/utils';

export interface Filter {
  // Country filter, whitelist
  country: Set<string>;
  // Supported product supported filter, whitelist in conjunction with product requested
  productsSupported: Set<string>;
  // Requested product filter,
  productsRequested: Set<string>;
  // Requested payments filter,
  paymentsSupported: Set<string>;
  // Institution user filter, whitelist in conjunction with product supported
  userType: Set<string>;
  // Link mode filter, whitelist
  // For each institution, compare the tags against the linkModes
  // E.g. if linkModes is {"real", "test"}
  // And an institution has tags ["real"]  it should be shown
  // But, if it has tag is ["fake"]  then it should not
  linkMode: Set<string>;
  // Institution status filter, whitelist
  // For each institution, compare the status against the institutionStatus
  // E.g. if institutionStatus is {"alpha", "beta"}
  // And an institution has status "SUPPORTED" it should not be shown
  // But if another institution has status "BETA" it should be shown
  institutionStatus: Set<string>;
}

interface InstitutionListItemProps {
  institution: Institution;
  isEnableReal: boolean;
  disableClick: boolean;
  setDisableClick: (arg: boolean) => void;
}

// default all filter empty => not filtering anything
export const defaultFilter: Filter = {
  country: new Set<string>(),
  productsSupported: new Set<string>(),
  productsRequested: new Set<string>(),
  paymentsSupported: new Set<string>(),
  userType: new Set<string>(),
  linkMode: new Set<string>(),
  institutionStatus: new Set<string>(),
};

function hasUserType(userType: Set<string>, institutionUserType: string[]): boolean {
  for (const ut of institutionUserType) {
    if (userType.has(ut.toLowerCase())) {
      return true;
    }
  }
  return false;
}

// convert string to lowercase and remove special characters
function searchFormatter(s: string): string {
  return s.toLowerCase().replace(/[^a-z]/g, '');
}

export function filterInstitutions(
  institutions: Institution[] | undefined,
  filter: Filter,
  searchQuery: string,
): Institution[] | undefined {
  if (!institutions) {
    return undefined;
  }

  // TODO: should refactor to a separate file when it grow
  const { country, productsRequested, userType, linkMode, institutionStatus } = filter;
  let productsSupported = filter.productsSupported;
  const intersect = (cs: string[], set: Set<string>) => cs.some((c) => set.has(c.toLowerCase()));
  const isInLinkMode = (i: Institution) => intersect(i.tags, linkMode);
  const isInInstitutionStatus = (i: Institution) => institutionStatus.has(i.status.toLowerCase());

  // by default we should list all institutions that supports anything other than payments
  // since all institutions that support data retrieval are expected to support accounts we use that as the default product filter
  const defaultProductsSupported = new Set(['accounts']);
  // if no filter is provided by default filter by  the default products supported options
  if (productsSupported.size === 0 && productsRequested.size === 0) {
    productsSupported = defaultProductsSupported;
  }

  const formattedSearchQuery = searchFormatter(searchQuery);

  // AND operation, an institution must fullfil all filter requirement
  return institutions.filter((i) => {
    if (country.size && !intersect(i.countries, country)) {
      return false;
    }
    // remove institution if the productsRequested is not empty and it does not support the products_requested
    if (productsRequested.size > 0 && !intersect(i.products_supported, productsRequested)) {
      return false;
    }
    // remove institution if the productsSupported is not empty and it does not support the products_supported
    if (productsSupported.size > 0 && !intersect(i.products_supported, productsSupported)) {
      return false;
    }

    // remove institution if paymentsSupported is not empty and it does not support the payment filtering for
    if (filter.paymentsSupported.size > 0 && !intersect(i.payment_info.payments_supported, filter.paymentsSupported)) {
      return false;
    }

    if (userType.size && !hasUserType(userType, i.user_type)) {
      return false;
    }
    if (linkMode.size && !isInLinkMode(i)) {
      return false;
    }
    if (institutionStatus.size && !isInInstitutionStatus(i)) {
      return false;
    }
    if (formattedSearchQuery.length > 0) {
      if (
        searchFormatter(i.institution_id).includes(formattedSearchQuery) ||
        searchFormatter(i.institution_name).includes(formattedSearchQuery) ||
        (i.login_url && searchFormatter(i.login_url).includes(formattedSearchQuery)) ||
        (i.parent_institution_name && searchFormatter(i.parent_institution_name).includes(formattedSearchQuery)) ||
        (i.portal_name && searchFormatter(i.portal_name).includes(formattedSearchQuery))
      )
        return true;
      else return false;
    }

    return true;
  });
}

const isTestInstitution = (institution: Institution) => institution.tags.find((t) => t === 'test');

const InstitutionListItem = ({
  institution,
  isEnableReal,
  disableClick,
  setDisableClick,
}: InstitutionListItemProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { handleMandateAuthFlow } = useMandateAuthorizationHelper();
  const { institution_id, login_url, institution_name, user_type } = institution;
  const { params, searchParamsString } = useSearchParams();
  const productFlow = getProductFlow(params.token);

  const handleClick = async () => {
    // if this tile is okay to click, then we should disable all other tiles from being clicked
    setDisableClick(true);
    amplitude.trackInstitutionSelect('Institution List', institution.institution_id);

    if (
      productFlow === ProductFlow.Mandate ||
      productFlow === ProductFlow.PaymentLink ||
      productFlow === ProductFlow.PaymentLinkSetup
    ) {
      // TODO: User type lodic needs to be updated after change in BE
      return await handleMandateAuthFlow(institution, user_type.includes('BUSINESS') ? 'BUSINESS' : 'INDIVIDUAL');
    }

    history.push({
      pathname: `/onboarding/login/${institution.institution_id}`,
      search: searchParamsString,
    });
  };

  const institutionIdForLogo = institutionToLogoMap[institution_id];
  const logoSrc = institutionIdForLogo ? `/img/square/${institutionIdForLogo}.svg` : '/img/institutions/default.svg';

  // Test institution will always exempted from being disabled
  // if click is meant to be disabled, then we should disable the tile regardless of institution type
  const isEnable = (isEnableReal || isTestInstitution(institution)) && !disableClick;
  return (
    <ListItem className="listItem" disabled={!isEnable} button onClick={handleClick}>
      <ListItemAvatar className="listItemLogoContainer">
        <Avatar className={`listItemLogo ${!isEnable && 'disabled'}`} alt={institution_id} src={logoSrc}>
          {/* if logo for insitution not found, default will be the fallback img*/}
          <Avatar className="listItemLogo" src="/img/institutions/default.svg" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        className="listItemText"
        primary={t(institution_name)}
        secondary={login_url ? new URL(login_url).hostname : undefined}
      />
    </ListItem>
  );
};

type institutionProps = {
  institutions: Institution[] | undefined;
  error: any;
};

const InstitutionContent = ({
  filteredInstitutions,
  isEnableReal,
}: {
  filteredInstitutions: Institution[] | undefined;
  isEnableReal: boolean;
}) => {
  const { t } = useTranslation();
  const [disableTileClick, setDisableTileClick] = useState(false);

  if (filteredInstitutions === undefined) {
    return (
      <Backdrop id="backdrop" open={true}>
        <div>
          <CircularProgress color="secondary" />
        </div>
      </Backdrop>
    );
  }

  if (filteredInstitutions.length === 0) {
    return <>{t('noValidInstitutionsMessage')}</>;
  }

  return (
    <>
      {filteredInstitutions.map((institution, idx) => (
        <InstitutionListItem
          key={`${idx}-${institution.institution_id}`}
          institution={institution}
          isEnableReal={isEnableReal}
          disableClick={disableTileClick}
          setDisableClick={(arg) => setDisableTileClick(arg)}
        />
      ))}
    </>
  );
};

export default function InstitutionsList(props: institutionProps): JSX.Element {
  const history = useHistory();

  const { t, i18n } = useTranslation();
  const { params } = useSearchParams();
  const language = params.language;
  const uiMode = params.uiMode;
  const { customizationInfo } = useCustomizations();

  const [filter, setFilter] = useState<Filter>(defaultFilter);
  const [isEnableReal, setEnableReal] = useState(false);

  // search-bar stuff
  const [searchQuery, setSearch] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const { mutate } = useSWRConfig();

  const filteredInstitutions = useMemo(() => {
    if (debouncedSearchQuery.length > 0) {
      amplitude.trackSearchBarText('Institution List', 'Institution Select Search Bar', debouncedSearchQuery);
    }
    return filterInstitutions(props.institutions, filter, debouncedSearchQuery);
  }, [props.institutions, filter, debouncedSearchQuery]);

  const countryFilterInfoText = useMemo(() => {
    const countryCodes = filter.country;
    if (!countryCodes.size) return undefined;
    if (countryCodes.size === 1) return toCountryFullname(Array.from(countryCodes)[0]);
    // TODO: Show show current countries filter once we allow user to set the filter
    return `#${filter.country.size} ${t('selectedText')}`;
  }, [filter.country, t]);

  useEffect(() => {
    // remove institution id in case we get here from navigating back
    amplitude.removeInstitutionId();
    amplitude.trackPageView(PAGE_VIEWS.INSTITUTION_LIST);
    mutate(['institutions', params.token]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.error) {
      const dest = getErrorPath({
        institutionId: 'finverse',
        error: props.error,
        userState: '',
        searchParamsString: location.search,
      });
      history.push(dest);
    }
  }, [props.error, history, i18n, language, t]);

  useEffect(() => {
    const parsedToken = parseToken<LinkToken | MandateAuthToken>(params.token);
    const linkModes = getLinkModes(parsedToken);
    const paymentsSupported = getPaymentsSupported(parsedToken);
    const institutionStatuses = getInstitutionStatuses(parsedToken);

    setFilter((prev) => {
      return {
        ...prev,
        country: new Set(params.countries?.toLowerCase().split(',')) || null,
        userType: new Set(params.user_type?.toLowerCase().split(',')) || null,
        productsSupported: new Set(params.products_supported?.toLowerCase().split(',')) || null,
        productsRequested: new Set(params.products_requested?.toLowerCase().split(',')) || null,
        paymentsSupported: paymentsSupported,
        linkMode: linkModes,
        institutionStatus: institutionStatuses,
      };
    });

    // for all cases, we will determine whether or not to show real institutions depending on realEnabled claim
    setEnableReal(getRealEnabled(parsedToken));
  }, [params]);

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // feed search query into search-bar
    setSearch(e.target.value);
  };

  const getHeader = () => {
    if (customizationInfo.logoUrl !== defaultCustomization.logoUrl) {
      return (
        <LinkHeader
          src={customizationInfo.logoUrl}
          message={t('institutionListSelectHeader')}
          institutionId="default"
        />
      );
    }
    return <LinkHeader variant="icon" message={t('institutionListSelectHeader')} institutionId="default" />;
  };

  return (
    <Screen showBackButton showCloseButton={uiMode !== UIModes.standalone}>
      {getHeader()}
      <MidContainer
        sx={{
          flex: '1 1 auto',
          overflowY: 'auto',
          minHeight: '0px',
          height: '0px',
        }}
      >
        <Box>
          {t('InstitutionList.location')}:
          <span className="locationText">{countryFilterInfoText || t('InstitutionList.all')}</span>
        </Box>
        <SearchBar onSearch={handleSearch} />
        <List
          sx={{
            flex: 1,
            overflow: 'auto',
            backgroundColor: 'background.default',
            marginTop: '0px',
          }}
        >
          <InstitutionContent filteredInstitutions={filteredInstitutions} isEnableReal={isEnableReal} />
        </List>
      </MidContainer>
    </Screen>
  );
}
