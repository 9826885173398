import { Switch, Route, Redirect } from 'react-router-dom';

import useSearchParams from '../hooks/useSearchParams';
import MandateAuthChecklistRedirectScreen from '../pages/mandate/AuthChecklistRedirectPage';
import MandateSetupProcessingScreen from '../pages/mandate/MandateSetupProcessing';
import MandateSubmissionFailedScreen from '../pages/mandate/SubmissionFailedScreen';
import MandateSubmittedScreen from '../pages/mandate/SubmittedScreen';
import PaymentLinkErrorScreen from '../pages/Onboarding/PaymentLinkErrorScreen';
import { MandateRoutes, PaymentRoutes } from './routes';

export default function MandateRouter(): JSX.Element {
  const { searchParamsString } = useSearchParams();

  return (
    <Switch>
      <Route path={MandateRoutes.MandateAuthChecklistRedirect}>
        <MandateAuthChecklistRedirectScreen />
      </Route>
      <Route path={MandateRoutes.PaymentLinkError}>
        <PaymentLinkErrorScreen />
      </Route>
      <Route path={MandateRoutes.SubmittedMessage}>
        <MandateSubmittedScreen />
      </Route>
      <Route path={MandateRoutes.SubmissionFailedMessage}>
        <MandateSubmissionFailedScreen />
      </Route>
      <Route path={MandateRoutes.MandateSetupProcessing}>
        <MandateSetupProcessingScreen />
      </Route>

      {/* the following paths should be under /payment, maintaining these redirects for backward compatibility with any back-end redirect */}
      <Route path={MandateRoutes.AutopayConsent}>
        <Redirect to={`${PaymentRoutes.AutopayConsent}?${searchParamsString}`} />
      </Route>
      <Route path={MandateRoutes.PaymentConfirm}>
        <Redirect to={`${PaymentRoutes.PaymentConfirm}?${searchParamsString}`} />
      </Route>
      <Route path={MandateRoutes.PaymentPoll}>
        <Redirect to={`${PaymentRoutes.PaymentPoll}?${searchParamsString}`} />
      </Route>
      <Route path={MandateRoutes.PaymentSubmitted}>
        <Redirect to={`${PaymentRoutes.PaymentSubmitted}?${searchParamsString}`} />
      </Route>
    </Switch>
  );
}
