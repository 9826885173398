// TODO: Translate text on this page
import { useCallback, useEffect, useMemo, useState } from 'react';

import HelpOutline from '@mui/icons-material/HelpOutline';
import { ClickAwayListener, Grid, Typography, useTheme, Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BulletOne } from '../../../components/BulletIcons';
import { LoadingButton } from '../../../components/Buttons';
import { MidContainer } from '../../../components/Containers';
import Image from '../../../components/Image';
import LinkHeader from '../../../components/LinkHeader';
import { ManualPaymentProvider } from '../../../constants/onboarding';
import { ConfirmManualPaymentRequest, ErrorResponse, UIModes } from '../../../entities';
import { PaymentStatus } from '../../../entities/api/payment';
import useAPIClient from '../../../hooks/useClient';
import useCustomizations from '../../../hooks/useCustomizations';
import useInterval from '../../../hooks/useInterval';
import useSearchParams from '../../../hooks/useSearchParams';
import useToggle from '../../../hooks/useToggle';
import { FpsRoutes, PaymentRoutes } from '../../../routers/routes';
import { decodeToken, getManualPaymentProvider } from '../../../services';
import amplitude, { PAGE_VIEWS } from '../../../services/amplitude';
import {
  getApiErrorPath,
  getErrorScreenPathForEmbeddedError,
  getPaymentCancelledScreenPath,
} from '../../../utils/error_page';
import sleep from '../../../utils/sleep';
import Screen, { GenericLoadingScreen } from '../../common/Screen';
import { useStyles } from '../../common/styles';
import { LineItemsDisplay } from '../listLineItems';
import { CancelManualPaymentModal } from './common';
import InstructionTwo from './InstructionTwo';

/**
 * Returns time1 - time 2, rounded up
 * @param time1
 * @param time2
 */
const getTimeDiffInMinutes = (time1: Date, time2: Date): number => {
  return Math.ceil((time1.valueOf() - time2.valueOf()) / (1000 * 60));
};

export default function ConfirmFpsScreen(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { customizationInfo } = useCustomizations();
  const { params, searchParamsString } = useSearchParams();
  const client = useAPIClient();
  const token = params.token;
  const decodedToken = useMemo(() => decodeToken(token), [token]);
  const manualPaymentProvider = getManualPaymentProvider(token);
  const { pageTitle, idLabel } = (() => {
    if (manualPaymentProvider === ManualPaymentProvider.SG_PAYNOW) {
      return {
        pageTitle: 'Pay by PayNow',
        idLabel: 'PayNow ID',
      };
    }
    return {
      pageTitle: 'Pay by FPS',
      idLabel: 'FPS ID',
    };
  })();
  const qrCodeExpiryTime = useMemo(() => new Date(parseInt(decodedToken.qrCodeExpiry)), [decodedToken]);

  const [loading, setLoading] = useState(false);
  const [showErrFeedback, setShowErrFeedback] = useState(false);
  const [accountholderName, setAccountholderName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [qrCodeTimeLeft, setQrCodeTimeLeft] = useState(getTimeDiffInMinutes(qrCodeExpiryTime, new Date()));

  const currency = decodedToken.currency;

  const format = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
  });

  useInterval(
    () => {
      const timeDiff = getTimeDiffInMinutes(qrCodeExpiryTime, new Date());
      setQrCodeTimeLeft(Math.max(timeDiff, 0));
    },
    // only perform time diffing if this is SG_PAYNOW
    manualPaymentProvider === ManualPaymentProvider.SG_PAYNOW ? 1000 : null,
  );

  const amount = format.format(decodedToken.amount);
  let fpsDetails;
  try {
    fpsDetails = JSON.parse(decodedToken.fps);
  } catch (err) {
    fpsDetails = {};
  }

  const [qrCodeBase64, setQrCodeBase64] = useState('');
  const [pageReady, setPageReady] = useState(false);
  const getQrCode = useCallback(
    async (token: string) => {
      const qrCodeResp = await client.getFpsQrCodeBase64(token);
      setQrCodeBase64(qrCodeResp.qr_code);
      setPageReady(true);
    },
    [client],
  );

  const pollForPaymentCompletion = useCallback(async () => {
    try {
      const pollStartTime = Date.now();
      // we want to poll atleast once; then break polling based on payment status or qr code expiry
      let isFirstPoll = true;

      // stop polling if the qr code has been expired for more than 15 minutes
      while (isFirstPoll || Date.now() - qrCodeExpiryTime.valueOf() <= 15 * 60 * 1000) {
        isFirstPoll = false;
        const payment = await client.getPayment(token);
        if (payment.status === PaymentStatus.EXECUTED) {
          return history.push({
            pathname: PaymentRoutes.GenericSuccess,
            search: searchParamsString,
          });
        }

        if (payment.status === PaymentStatus.FAILED) {
          return history.push(getErrorScreenPathForEmbeddedError(payment.error, searchParamsString));
        }

        if (payment.status === PaymentStatus.CANCELLED) {
          return history.push(getPaymentCancelledScreenPath(searchParamsString, payment.payment_id));
        }

        // for the first 10 minutes, we will poll every second; after that we will drop down to 5s
        const pollDurationInMs = Date.now() - pollStartTime;
        await sleep(pollDurationInMs < 10 * 60 * 1000 ? 1000 : 5000);
      }
    } catch (err) {
      return history.push(getApiErrorPath(err as AxiosError<ErrorResponse>, searchParamsString));
    }
  }, [client, token, history, searchParamsString, qrCodeExpiryTime]);

  useEffect(() => {
    getQrCode(token);
  }, [getQrCode, token]);

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.MANUAL_PAYMENT_CONFIRMATION);
  }, []);

  useEffect(() => {
    if (manualPaymentProvider === ManualPaymentProvider.SG_PAYNOW) {
      pollForPaymentCompletion();
    }
  }, [manualPaymentProvider, pollForPaymentCompletion]);

  const submitAction = useCallback(
    async (accountholderName: string) => {
      // account holder name only required for HK_FPS
      if (manualPaymentProvider === ManualPaymentProvider.HK_FPS && accountholderName.trim() === '') {
        setShowErrFeedback(true);
        return;
      }
      // 1. set button to loading
      setLoading(true);

      const requestBody = ((): ConfirmManualPaymentRequest => {
        if (manualPaymentProvider === ManualPaymentProvider.HK_FPS) {
          return { accountholder_name: accountholderName };
        }
        return {};
      })();

      // 2. submit account holder name
      try {
        const data = await client.confirmManualPayment(token, requestBody);
        amplitude.trackButtonClick(PAGE_VIEWS.MANUAL_PAYMENT_CONFIRMATION, 'I have paid (async response)', {
          paymentId: data.payment_id,
          status: data.status,
        });

        // 3. Navigate to next page
        if (data.status === PaymentStatus.EXECUTED) {
          return history.push({
            pathname: PaymentRoutes.GenericSuccess,
            search: searchParamsString,
          });
        } else {
          return history.push({
            pathname: FpsRoutes.PaymentSubmitted,
            search: searchParamsString,
          });
        }
      } catch (err) {
        return history.push(getApiErrorPath(err as any, searchParamsString));
      }
    },
    [history, searchParamsString, client, token, manualPaymentProvider],
  );

  if (!pageReady) {
    return <GenericLoadingScreen />;
  }

  const BottomStickyComponent = () => {
    return (
      <>
        {manualPaymentProvider !== ManualPaymentProvider.SG_PAYNOW && (
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={() => {
              amplitude.trackButtonClick(PAGE_VIEWS.MANUAL_PAYMENT_CONFIRMATION, 'I have paid');
              submitAction(accountholderName);
            }}
          >
            {t('I have paid')}
          </LoadingButton>
        )}
        {manualPaymentProvider === ManualPaymentProvider.SG_PAYNOW && (
          <Typography mb={-2}>
            <b>{qrCodeTimeLeft}</b> {t('minutes left to pay')}
          </Typography>
        )}
        <Button
          sx={{ paddingBottom: 2, textTransform: 'none', textDecoration: 'underline' }}
          size="large"
          onClick={() => {
            amplitude.trackButtonClick(PAGE_VIEWS.MANUAL_PAYMENT_CONFIRMATION, 'Select another payment method');
            setOpenDialog(true);
          }}
          color="secondary"
        >
          {t('Select another payment method')}
        </Button>
      </>
    );
  };

  return (
    <Screen
      showBackButton
      showCloseButton={params.uiMode !== UIModes.standalone}
      bottomStickyComponent={<BottomStickyComponent />}
      onBack={() => setOpenDialog(true)}
    >
      <LinkHeader src={customizationInfo.logoUrl} alt="Header logo" message={pageTitle} />
      <MidContainer sx={{ px: 2, py: 2 }}>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          {t('Pay to')}: {customizationInfo.displayName ?? decodedToken.customerName}
        </Typography>
        <LineItemsDisplay paymentType="MANUAL" sx={{ maxHeight: '20px', m: 0 }} />
        <Grid container alignItems="center" display="flex" sx={{ mt: 2 }} spacing={1}>
          <Grid item>
            <BulletOne />
          </Grid>
          <Grid item>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              {manualPaymentProvider === ManualPaymentProvider.SG_PAYNOW
                ? t('Scan PayNow QR to pay')
                : t('Send payment to')}
              :
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1}>
          <Grid container item xs={6} display="flex" alignItems="center" justifyContent="center">
            <Image sx={{ height: '150px' }} src={`data:image/jpeg;base64,${qrCodeBase64}`} alt="FPS qr code"></Image>
          </Grid>
          <Grid container item xs={6} spacing={1}>
            <Grid item>
              <Typography fontWeight="bold">{`${currency} ${amount}`}</Typography>
            </Grid>
            <Grid item>
              <Typography>
                <b>{t(idLabel)}</b>: {fpsDetails.id}
              </Typography>
            </Grid>
            <Grid item>
              {manualPaymentProvider === ManualPaymentProvider.HK_FPS && (
                <HkFpsRecipient legalName={fpsDetails.legal_name} />
              )}
              {manualPaymentProvider === ManualPaymentProvider.SG_PAYNOW && (
                <SgPayNowRecipient legalName={fpsDetails.legal_name} />
              )}
            </Grid>
          </Grid>
        </Grid>
        <InstructionTwo
          showErrFeedback={showErrFeedback}
          accountholderName={accountholderName}
          manualPaymentProvider={manualPaymentProvider}
          setAccountholderName={setAccountholderName}
        />
      </MidContainer>
      <CancelManualPaymentModal
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        currency={currency}
        amount={amount}
      />
    </Screen>
  );
}

const HkFpsRecipient = ({ legalName }: { legalName: string }) => {
  const { t } = useTranslation();
  const [tooltipOpen, toggleTooltip] = useToggle(false);
  const classes = useStyles(useTheme());

  return (
    <Typography>
      <b>{t('Recipient')}</b>: {legalName} ({t('payment processor')}){' '}
      <ClickAwayListener onClickAway={() => toggleTooltip(false)}>
        <Tooltip
          title={t('Finverse will process this payment') as string}
          arrow
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => toggleTooltip()}
          open={tooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <span style={{ verticalAlign: 'text-bottom' }}>
            <HelpOutline onClick={() => toggleTooltip()} className={classes.icon} />
          </span>
        </Tooltip>
      </ClickAwayListener>
    </Typography>
  );
};

const SgPayNowRecipient = ({ legalName }: { legalName: string }) => {
  const { t } = useTranslation();

  return (
    <Typography>
      <b>{t('Recipient')}</b>: {legalName}
    </Typography>
  );
};
