import { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';

import { LoadingButton } from '../../components/Buttons';
import { MidContainer } from '../../components/Containers';
import LinkHeader from '../../components/LinkHeader';
import { UIModes } from '../../entities';
import useAPIClient from '../../hooks/useClient';
import useSearchParams from '../../hooks/useSearchParams';
import amplitude, { PAGE_VIEWS } from '../../services/amplitude';
import Screen from '../common/Screen';
import { getErrorPath } from '../common/utils';

export default function MandateAuthChecklistRedirectScreen(): JSX.Element {
  const { t } = useTranslation();
  const { params } = useSearchParams();
  const [instituionName, setInstituionName] = useState('');

  const history = useHistory();
  const client = useAPIClient();

  useEffect(() => {
    amplitude.trackPageView(PAGE_VIEWS.ACTION_REQUIRED);
  }, []);

  // get parent instituion name
  const { data: institutions, error } = useSWRImmutable(
    ['institutions', params.token],
    (_: string, token: string) => client.getInstitutions(token),
    {
      errorRetryCount: 0,
    },
  );

  useEffect(() => {
    if (error) {
      const dest = getErrorPath({
        institutionId: 'finverse',
        error: error,
        userState: '',
        searchParamsString: location.search,
      });
      history.push(dest);
    }

    const inst = institutions?.find((e) => {
      return e.institution_id === params.institution_id;
    });

    setInstituionName(inst?.parent_institution_name ?? 'Bank');
  }, [client, error, history, institutions, params.institution_id, params.token]);

  return (
    <Screen
      showCloseButton={params.uiMode !== UIModes.standalone}
      showBackButton={true}
      bottomStickyComponent={
        <LoadingButton
          variant="contained"
          loading={false}
          onClick={() => {
            amplitude.trackButtonClick('Auth Checklist Redirect Screen', 'Button to redirect to bank');
            window.location.href = params.auth_checklist_redirect_url;
          }}
        >
          {t('messageScreenContinue')}
        </LoadingButton>
      }
    >
      <LinkHeader message={t('authChecklistRedirectHeader')} institutionId={params.institution_id} />
      <MidContainer textAlign="center" sx={{ gap: 1 }}>
        <Typography marginTop={'100px'} gutterBottom>
          {t('authChecklistRedirectText', { bankName: instituionName })}
        </Typography>
      </MidContainer>
    </Screen>
  );
}
