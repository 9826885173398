import { useCallback, useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Typography, Button, Container, Tooltip, Grid, IconButton, Link } from '@mui/material';
import { TFunction, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { LoadingButton, PrimaryButton } from '../../../components/Buttons';
import LinkHeader from '../../../components/LinkHeader';
import { MandateStatus, ProductFlow } from '../../../constants/onboarding';
import { UIModes } from '../../../entities';
import { CardStatus } from '../../../entities/api/card';
import { NonSensitivePaymentUserInfo } from '../../../entities/api/mandate';
import { PaymentMethodFvLinkResponse, PaymentMethodType } from '../../../entities/api/paymentMethod';
import useAPIClient from '../../../hooks/useClient';
import useCustomizations from '../../../hooks/useCustomizations';
import useSearchParams from '../../../hooks/useSearchParams';
import { PaymentRoutes } from '../../../routers/routes';
import { decodeToken, getProductFlow } from '../../../services';
import amplitude, { PAGE_VIEWS } from '../../../services/amplitude';
import { getApiErrorPath } from '../../../utils/error_page';
import {
  getMaskedAccountNumberFromPaymentMethod,
  getRecipientNameFromPaymentMethod,
  getStatusFromPaymentMethod,
} from '../../../utils/payment_method';
import { translateErrorDetails } from '../../../utils/translate';
import { DisplaySupportMessage } from '../../common/components';
import Screen, { GenericLoadingScreen } from '../../common/Screen';
import { ReferenceIdBox } from '../../Onboarding/MessageScreen';
import { BottomContainerContent } from '../BottomContainer';
import { LineItemsDisplay } from '../listLineItems';

const getHeaderText = (t: TFunction, productFlow: ProductFlow, displayName?: string): string => {
  if (productFlow === ProductFlow.PaymentLinkSetup) {
    return t('setupPaymentMethod');
  }

  if (displayName) {
    return `${t('Pay')} ${displayName}`;
  }

  return t('paymentConfirm');
};

const getButtonText = (t: TFunction, productFlow: ProductFlow): string => {
  if (productFlow === ProductFlow.PaymentLinkSetup) {
    return t('messageScreenContinue');
  }

  return t('submitPayment');
};

const isErrorStatus = (paymentMethod: PaymentMethodFvLinkResponse) => {
  if (paymentMethod.payment_method_type === PaymentMethodType.MANDATE) {
    const status = paymentMethod.mandate.mandate_status;
    return status === MandateStatus.FAILED || status === MandateStatus.REVOKED || status === MandateStatus.UNKNOWN;
  }

  if (paymentMethod.payment_method_type === PaymentMethodType.CARD) {
    const status = paymentMethod.card.status;
    return status != CardStatus.SUCCEEDED;
  }

  return true;
};

const shouldAllowChangePaymentMethod = (paymentMethod: PaymentMethodFvLinkResponse): boolean => {
  if (paymentMethod.payment_method_type === PaymentMethodType.MANDATE) {
    return paymentMethod.mandate.mandate_status === MandateStatus.SUCCEEDED;
  }

  if (paymentMethod.payment_method_type === PaymentMethodType.CARD) {
    return paymentMethod.card.status === CardStatus.SUCCEEDED;
  }

  return false;
};

const AuthStatusIndicator = ({ paymentMethod }: { paymentMethod: PaymentMethodFvLinkResponse }) => {
  const { t } = useTranslation();
  const isError = isErrorStatus(paymentMethod);

  // We will render the mapped status, not the real one
  const status = getStatusFromPaymentMethod(paymentMethod).mappedStatus;

  if (paymentMethod.payment_method_type === PaymentMethodType.CARD) {
    // no indicator returned for cards
    return null;
  }

  return (
    <>
      <Typography fontWeight="bold" variant="h6" gutterBottom>
        {t('Debit authorization status')}:
      </Typography>
      <Button
        startIcon={isError ? <ErrorIcon /> : <CheckIcon />}
        size="large"
        sx={{ backgroundColor: '#E0E0E0', borderRadius: 2, paddingX: 2, paddingY: 1 }}
        color={isError ? 'error' : 'primary'}
      >
        <Typography fontWeight="bold">{t(status)}</Typography>
      </Button>
    </>
  );
};

const PayFromSection = ({
  paymentMethod,
  institutionName,
  setPageLoading,
}: {
  paymentMethod: PaymentMethodFvLinkResponse;
  institutionName: string;
  setPageLoading: (input: boolean) => void;
}) => {
  const { t } = useTranslation();
  const client = useAPIClient();
  const history = useHistory();
  const { params, searchParamsString } = useSearchParams();

  const changePaymentMethod = useCallback(
    async (token: string) => {
      try {
        const response = await client.changePaymentMethod(token);
        // this redirect url will have all the necessary search params
        window.location.href = response.redirect_url;
      } catch (err) {
        history.push(getApiErrorPath(err as any, searchParamsString));
      }
    },
    [client, history, searchParamsString],
  );

  const payFromInstitution = (() => {
    if (paymentMethod.payment_method_type === PaymentMethodType.MANDATE) {
      return institutionName;
    }
    if (paymentMethod.payment_method_type === PaymentMethodType.CARD) {
      return paymentMethod.card.card_details?.brand ?? '';
    }
    return '';
  })();

  const maskedAccountNumber = getMaskedAccountNumberFromPaymentMethod(paymentMethod);

  return (
    <>
      <Grid container alignItems="center" spacing={1} direction="row">
        <Grid item flex={1}>
          <Typography variant="body1" fontWeight="bold">
            {t('Pay from')}:
          </Typography>
        </Grid>
        {shouldAllowChangePaymentMethod(paymentMethod) && (
          <Grid item>
            <Link
              id="changePaymentMethod"
              component="button"
              variant="body1"
              style={{ color: 'grey', fontWeight: 'bold' }}
              onClick={async () => {
                setPageLoading(true);
                changePaymentMethod(params.token);
              }}
            >
              {t('Change')}
            </Link>
          </Grid>
        )}
      </Grid>
      <Typography variant="body2">{payFromInstitution}</Typography>
      {maskedAccountNumber !== '' && <Typography variant="body2">{maskedAccountNumber}</Typography>}
    </>
  );
};

const AutopaySection = ({ autopayConsent }: { autopayConsent: boolean }) => {
  const { t } = useTranslation();
  const autopayText = autopayConsent ? t('ON') : t('OFF');

  const tooltipText = t('paymentConfirmationScreenAutopayTooltipText');

  return (
    <Grid container alignItems="center" spacing={1} id="autopay" direction="row">
      <Grid item>
        <Typography variant="body1" fontWeight="bold">
          {`${t('Autopay')}:`}
        </Typography>
      </Grid>
      <Grid item>
        <Typography color={autopayConsent ? 'primary' : ''} variant="body1" fontWeight="bold">
          {autopayText}
        </Typography>
      </Grid>
      <Grid item>
        <Tooltip title={tooltipText} placement="top-start" arrow>
          <IconButton size="small">
            <InfoIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

interface SuccessPaymentStatusProps {
  paymentMethod: PaymentMethodFvLinkResponse;
  institutionName: string;
  paymentUserInfo: NonSensitivePaymentUserInfo;
  /**
   * Function that would be used to query mandate status on trying to confirm payment
   * Which will also set the parent components state
   */
  getPaymentMethod: (token: string) => Promise<PaymentMethodFvLinkResponse>;
  productFlow: ProductFlow;
}

const SuccessPaymentStatus = ({
  paymentMethod,
  institutionName,
  paymentUserInfo,
  getPaymentMethod,
  productFlow,
}: SuccessPaymentStatusProps) => {
  const { t } = useTranslation();
  const { customizationInfo } = useCustomizations();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const history = useHistory();
  const client = useAPIClient();
  const { params, searchParamsString } = useSearchParams();
  const uiMode = params.uiMode;

  // we get all payment-related page data from params token
  const token = decodeToken(params.token) as Record<string, string>;
  const invoiceNum = token.invoiceNumber;

  const confirmPayment = useCallback(
    async (token: string) => {
      try {
        const paymentMethod = await getPaymentMethod(token);
        if (isErrorStatus(paymentMethod)) {
          // getPaymentMethod will update the paymentMethod in top level state, which will change
          // conditional rendering from <SuccessPaymentStatus> to <ErrorPaymentStatus>
          // return so that we don't execute the confirmPayment API call (which will fail anyway)
          return;
        }

        await client.confirmPayment(token);
        setButtonLoading(false);

        // add additional search params for the payment submitted page
        const newSearchParams = new URLSearchParams(searchParamsString);
        newSearchParams.set('paymentMethodType', paymentMethod.payment_method_type as string);
        newSearchParams.set('recipientEntityName', paymentMethod.recipient_entity_name);
        if (paymentMethod.payment_method_type === PaymentMethodType.MANDATE) {
          newSearchParams.set('mandateStatus', paymentMethod.mandate.mandate_status as string);
        }

        history.push({
          pathname: PaymentRoutes.PaymentPoll,
          search: newSearchParams.toString(),
        });
      } catch (err) {
        history.push(getApiErrorPath(err as any, searchParamsString));
      }
    },
    [history, searchParamsString, client, getPaymentMethod],
  );

  const recipientName = getRecipientNameFromPaymentMethod(paymentMethod);

  if (pageLoading) {
    return <GenericLoadingScreen />;
  }

  const InvoiceHeaderAndLineItems = () => {
    // for the Payment Link Setup flow, we will hide the "INVOICE" header
    if (productFlow === ProductFlow.PaymentLinkSetup) {
      return <></>;
    } else {
      return (
        <>
          <Typography variant="body2">
            {t('invoice')}: #{invoiceNum}
          </Typography>
          <LineItemsDisplay paymentType="MANDATE" sx={{ height: '25px', m: 0 }} />
        </>
      );
    }
  };

  const BottomButton = () => {
    if (productFlow !== ProductFlow.PaymentLinkSetup) {
      return (
        <LoadingButton
          variant="contained"
          loading={buttonLoading}
          onClick={() => {
            amplitude.trackButtonClick(PAGE_VIEWS.PAYMENT_CONFIRMATION, 'Confirm Payment');
            setButtonLoading(true);
            // make request to navigate
            confirmPayment(params.token);
          }}
        >
          {getButtonText(t, productFlow)}
        </LoadingButton>
      );
    }

    return <BottomContainerContent></BottomContainerContent>;
  };

  return (
    <Screen
      showCloseButton={uiMode !== UIModes.standalone}
      showBackButton={false}
      bottomStickyComponent={BottomButton()}
    >
      <LinkHeader
        src={customizationInfo.logoUrl}
        message={getHeaderText(t, productFlow, customizationInfo.displayName)}
        alt="Header logo"
      />
      <Container sx={{ paddingX: 5, paddingTop: 4, flexDirection: 'column', flex: 1 }}>
        <Typography variant="body2">
          {t('Pay to')}: {recipientName}
        </Typography>
        <InvoiceHeaderAndLineItems />
        <Box height={30} />
        <AuthStatusIndicator paymentMethod={paymentMethod} />
        <Box height={15} />
        <PayFromSection
          paymentMethod={paymentMethod}
          institutionName={institutionName}
          setPageLoading={setPageLoading}
        />
        <Box height={15} />
        <AutopaySection autopayConsent={paymentUserInfo.autopay_consent} />
      </Container>
    </Screen>
  );
};

type ErrorPaymentStatusProps = {
  paymentMethod: PaymentMethodFvLinkResponse;
};

const ErrorPaymentStatus = ({ paymentMethod }: ErrorPaymentStatusProps) => {
  const { t } = useTranslation();
  const { customizationInfo } = useCustomizations();
  const { params } = useSearchParams();
  const uiMode = params.uiMode;
  const token = decodeToken(params.token);
  const retryUrl = token.retryUrl as string;
  const mandateId = token.mandateId as string;

  const { title, errorDetails } = (() => {
    if (paymentMethod.payment_method_type === PaymentMethodType.MANDATE) {
      const mandate = paymentMethod.mandate;
      return {
        title:
          mandate.mandate_status === MandateStatus.FAILED ? t(mandate.error.message) : t('Debit Authorization Error'),
        errorDetails:
          mandate.mandate_status === MandateStatus.FAILED
            ? translateErrorDetails(t, mandate.error.details)
            : t('paymentConfirmationErrText2'),
      };
    }

    // TODO: We probably want a translation for the fallback title
    return {
      title: 'Error',
      errorDetails: 'Unknown error',
    };
  })();

  return (
    <Screen
      showCloseButton={uiMode !== UIModes.standalone}
      showBackButton={false}
      bottomStickyComponent={
        <PrimaryButton
          variant="contained"
          onClick={() => {
            window.location.replace(retryUrl);
          }}
        >
          {t('messageScreenError')}
        </PrimaryButton>
      }
    >
      <LinkHeader src={customizationInfo.logoUrl} message={title} />
      <Container sx={{ paddingX: 5, paddingTop: 4, flexDirection: 'column', flex: 1 }}>
        <AuthStatusIndicator paymentMethod={paymentMethod} />
        <Box height={25} />
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          {t('errorDetails')}:
        </Typography>
        <Typography variant="body1" gutterBottom>
          {errorDetails}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {DisplaySupportMessage()}
          <ReferenceIdBox institutionId={'finverse'} refernceId={mandateId} message="mandateIdMessage" />
        </Typography>
      </Container>
    </Screen>
  );
};

export default function PaymentConfirmationScreen(): JSX.Element {
  const { params, searchParamsString } = useSearchParams();
  const client = useAPIClient();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodFvLinkResponse>({
    payment_method_id: '',
    payment_method_type: PaymentMethodType.UNKNOWN,
    recipient_entity_name: '',
  });
  const [paymentUserInfo, setPaymentUserInfo] = useState<NonSensitivePaymentUserInfo>({
    payment_user_id: '',
    autopay_consent: false,
    autopay_prefill: false,
    customer_app_id: '',
  });
  const [institutionName, setInstitutionName] = useState<string>('');

  // used to indicate is we are getting the status from API
  const [gettingStatus, setGettingStatus] = useState<boolean>(true);

  const getPaymentMethod = useCallback(
    async (token: string): Promise<PaymentMethodFvLinkResponse> => {
      const response = await client.getPaymentMethod(token);
      setPaymentMethod(response);

      const institutionId = (() => {
        if (response.payment_method_type === PaymentMethodType.MANDATE) {
          if (response.mandate.sender_account !== undefined) {
            return response.mandate.sender_account.institution_id;
          }
          return response.mandate.institution_id;
        }
        return null;
      })();

      if (institutionId !== null && !isErrorStatus(response)) {
        const institutionResp = await client.getInstitution(token, institutionId);
        setInstitutionName(institutionResp.institution_name);
      }

      return response;
    },
    [client],
  );

  const getPaymentUserInfo = useCallback(
    async (token: string) => {
      const res = await client.getNonSensitivePaymentUserInfo(token);
      setPaymentUserInfo(res);
    },
    [client],
  );

  const getPageData = useCallback(
    async (token: string) => {
      try {
        await Promise.all([getPaymentUserInfo(token), getPaymentMethod(token)]);
        setGettingStatus(false);
      } catch (err) {
        getApiErrorPath(err as any, searchParamsString);
      }
    },
    [getPaymentUserInfo, getPaymentMethod, searchParamsString],
  );

  const productFlow = getProductFlow(params.token);

  // on component load, we want to get
  useEffect(() => {
    setGettingStatus(true);
    getPageData(params.token);
  }, [getPageData, params.token]);

  useEffect(() => {
    if (paymentMethod.payment_method_id === '') {
      // only publish amplitude message if we have loaded the payment method
      return;
    }
    const eventProperties = amplitude.getLandingPageProperties();
    const paymentMethodStatus = getStatusFromPaymentMethod(paymentMethod);
    amplitude.trackPageView(PAGE_VIEWS.PAYMENT_CONFIRMATION, {
      ...eventProperties,
      realPaymentMethodStatus: paymentMethodStatus.realStatus,
      mappedPaymentMethodStatus: paymentMethodStatus.mappedStatus,
      paymentMethodStatus: paymentMethodStatus.realStatus, // keep old amplitude field for bw compatibility (e.g. some amplitude flows SL has already setup)
      paymentMethodType: paymentMethod.payment_method_type,
      paymentMethodId: paymentMethod.payment_method_id,
    });
  }, [paymentMethod]);

  if (gettingStatus) {
    return <GenericLoadingScreen />;
  }

  return isErrorStatus(paymentMethod) ? (
    <ErrorPaymentStatus paymentMethod={paymentMethod} />
  ) : (
    <SuccessPaymentStatus
      paymentMethod={paymentMethod}
      paymentUserInfo={paymentUserInfo}
      institutionName={institutionName}
      getPaymentMethod={getPaymentMethod}
      productFlow={productFlow}
    />
  );
}
